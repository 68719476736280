export const firebaseConfig = {
  apiKey: 'AIzaSyA7eYUQGSJIsLaBePdMFWG3OWzVTfkic1I',
  authDomain: 'peca-live.firebaseapp.com',
  databaseURL: 'https://peca-live.firebaseio.com',
  projectId: 'peca-live',
  storageBucket: 'peca-live.appspot.com',
  messagingSenderId: '759961842358',
  appId: '1:759961842358:web:d0a0fbbf9be60fd9c72c17',
  measurementId: 'G-G1XZ8N5NP2',
}
